<template>
	<v-sheet v-if="team_id != null && teamData != null">
		<v-toolbar flat>
			<v-toolbar-title>编辑团队信息</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				text
				@click="$router.go(-1)"
				elevation="0">
				返回上页
			</v-btn>
		</v-toolbar>
		<v-divider></v-divider>
		<v-form
				ref="form"
				class="pa-3"
				v-model="valid"
				lazy-validation
				@submit.prevent="onFormSubmit"
			>
		<v-container>
			<div class="d-flex align-center justify-space-between mr-3 mt-3 mb-4">
				<v-subheader><v-icon left>mdi-information</v-icon>基本信息</v-subheader>
				<v-chip :color="teamData.archived ? 'error' :'success'">{{teamStatus}}</v-chip>
			</div>
			<v-row>
				<v-col cols="6" md="6" sm="12">
					<v-text-field
						v-model="teamData.name"
						:counter="2"
						dense
						outlined
						:rules="nameRules"
						label="团队名称"
						required
					></v-text-field>
				</v-col>
				<!-- <v-col cols="3" md="3" sm="12">
					<v-select
						outlined
						v-model="teamData.type"
						:items="teamTypes"
						item-text="text"
						dense
						item-value="value"
						label="团队类型"
					></v-select>
				</v-col> -->
				<v-col cols="3" md="3" sm="12" v-if="teamData.owner">
					<v-text-field
						v-model="teamData.owner.name"
						dense
						outlined
						label="档案创建人"
						disabled
					></v-text-field>
				</v-col>
			</v-row>
		</v-container>
		<v-container class="pa-0 mt-6">
			<div class="pa-1 d-flex justify-space-between align-center">
				<v-subheader>
					<v-icon left>mdi-account-multiple-plus</v-icon>
					团队成员</v-subheader>
					<v-spacer></v-spacer>
					
					<v-btn
						color="primary"
						small
						elevation="0"
						@click="dialogSearchWorker=true"
					>
						<v-icon small>mdi-plus</v-icon>
						添加工人
					</v-btn>
					<v-divider vertical class="ml-5 mr-2"></v-divider>
					<div class="d-flex justify-end py-2 pr-4">
					<!--- team filter --->
					<template v-if="isFiltering">
						<div class="d-flex align-center">
						<v-btn
							color="primary"
							text
							small
							tile
							@click="dlgFilterTeamWorker = true"
							>
							<v-icon small left>
								mdi-filter-variant
							</v-icon>
							<div class="body-2 mr-2">{{filter_start_date | dateFormat}} ~ {{filter_end_date | dateFormat}}</div>
						</v-btn>
						<v-divider vertical class="mx-2"></v-divider>
							<v-btn color="error" small text @click="clearFilters">
								<v-icon small left>mdi-close</v-icon> 删除过滤
							</v-btn>
						</div>
					</template>
					<template v-else>
						<div style="max-width:150px;">
								<v-btn
									color="primary"
									text
									small
									tile
									@click="dlgFilterTeamWorker = true"
									>
									<v-icon small left>
										mdi-filter-variant
									</v-icon>
									过滤工人
								</v-btn>
						</div>
					</template>
				</div>
				<v-dialog v-model="dlgFilterTeamWorker" max-width="480">
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
							@submit.prevent="filterWorker"
						>
						<v-card>
							<v-card-title>
								过滤工人
							</v-card-title>
							<v-divider></v-divider>
								<div class="body-1 px-6 mt-4">
									过滤出在以下工作日期间工作的工人：
								</div>
								<v-container class="px-6">
									<v-row>
										<v-col>
											<v-text-field
												:value="computedDateFormattedDatefns(filter_start_date)"
												outlined
												:rules="requiredRules"
												dense
												hide-details
												label="工人开始日期"
												readonly
												required
												@click="startDateMenu = true"
												@click:clear="filter_start_date = null"
											></v-text-field>
											<v-dialog v-model="startDateMenu" max-width="290">
												<v-date-picker
													v-model="filter_start_date"
													:first-day-of-week="0"
													show-current
													locale="zh-cn"
													class="pb-6"
													@change="startDateMenu = false"
												></v-date-picker>
											</v-dialog>
										</v-col>
										<v-col>
											<v-text-field
												:value="computedDateFormattedDatefns(filter_end_date)"
												outlined
												:rules="requiredRules"
												dense
												hide-details
												label="工人结束日期"
												readonly
												required
												@click="endDateMenu = true"
												@click:clear="filter_end_date = null"
											></v-text-field>
											<v-dialog v-model="endDateMenu" max-width="290">
												<v-date-picker
													v-model="filter_end_date"
													:first-day-of-week="0"
													show-current
													locale="zh-cn"
													class="pb-6"
													@change="endDateMenu = false"
												></v-date-picker>
											</v-dialog>
										</v-col>
									</v-row>
								</v-container>
							<v-card-actions class="justify-end px-6 mt-3">
								<v-btn
									color="error"
									text
									@click="dlgFilterTeamWorker = false"
								>
									取消
								</v-btn>
								<v-btn
									:disabled="!valid"
									color="success"
									elevation="0"
									block
									@click="validate"
									type="submit"
								>
									过滤工人
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
				</v-dialog>
			</div>
			<v-divider></v-divider>
			<template v-if="loading">
				<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
					<v-progress-circular
						:size="70"
						:width="3"
						color="primary"
						indeterminate
					></v-progress-circular>
					<div class="subtitle-1 mt-4 ">刷新数据中</div>
				</v-sheet>
			</template>
			<template v-else>
				<div class="body-2" v-if="assignedWorkers && assignedWorkers.length">
					<v-data-table
						:headers="workerHeaders"
						:items="assignedWorkers"
						:items-per-page="itemPerPage"
						hide-default-footer
						>
						<template v-slot:item.name="{item}">
							<RouterLink :to="{ name: 'viewAccount', params: {id:item.id}}">{{ item.name }}</RouterLink>
						</template>
						<template v-slot:item.actions="{item}">
							 <v-btn color="error" elevation="0" text small class="mr-2"
								@click="removeWorker(item)"
							>
								<v-icon small left>mdi-account-remove</v-icon>
								移除
							</v-btn>
						</template>
						<template v-slot:item.role="{item}">
							{{ item.role | roleType }}
						</template>
					</v-data-table>
				</div>
				<div class="body-2 text-center pa-3" v-else>
					团队暂时没有任何成员
				</div>
			</template>
			<v-divider></v-divider>
			<div class="d-flex justify-end pa-3">
				<v-btn
					:disabled="!valid"
					color="success"
					elevation="0"
					@click="validate"
					type="submit"
				>
					<v-icon left>mdi-content-save</v-icon>
					更新信息
				</v-btn>
			</div>
		</v-container>
	</v-form>

		<!--- 工人搜索框 --->
		<v-dialog v-model="dialogSearchWorker" max-width="760">
			<v-card>
				<v-card-title>搜索并添加工人</v-card-title>
				<v-divider></v-divider>
				<user-list-tab :role="activeRole" :status="status"
					@on-filter-role="onFilterRole($event)"
					@on-filter-status="onFilterStatus($event)"
					>
					<template v-slot="{ item }">
						<v-btn 
							color="success"
							elevation="0"
							small
							class="mr-2"
							@click="assignWorkerToTeam(item)"
						>
							<v-icon small>
								mdi-plus
							</v-icon>
							添加
						</v-btn>
						<v-btn 
							color="info"
							elevation="0"
							small
							class="mr-2"
							:to="{ name: 'viewAccount', params: {id:item.id}}"
						>
							<v-icon small>
								mdi-pencil
							</v-icon>
								编辑
						</v-btn>
					</template>
				</user-list-tab>
			</v-card>
		</v-dialog>

		<!--- 载入和信息框 --->
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>

		<!--- 确认移除工人对话框 --->
		<v-dialog v-if="deleteWorker" v-model="dlgConfirmation" max-width="320">
				<v-card>
					<v-card-title>确认移除？</v-card-title>
					<v-card-text>确认从团队中移除工人 - <strong>{{deleteWorker.name}}？</strong> </v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="error" text @click="dlgConfirmation = false">取消</v-btn>
						<v-btn elevation="0" color="error" @click="confirmDeletion">确认移除</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
	</v-sheet>
</template>

<script>

import { mapState } from 'vuex';
import WorkTeamService from '../../services/WorkTeam';
import {  parse, format } from 'date-fns';
import UserListTab from '../../components/User/UserListTab.vue';

export default {
	name: 'viewTeam',
	components: {
		UserListTab
	},
	created(){
		this.team_id = parseInt(this.$route.params.id);
		if(this.team_id){
			this.getTeamDetail(this.team_id);
		}
	},
	data: () => ({
		team_id: null,
		teamData: {
			id: null,
			name: null,
			type: null,
			owner: null,
			workers: [],
			archived: 0
		},
		dlgConfirmation: false,
		valid: true,
		requiredRules: [
			v => !!v || '必填内容',
		],
		nameRules: [
			v => !!v || '请输入团队名称',
			v => (v && v.length >= 2) || '团队名称至少2个字',
		],
		workerHeaders: [
			{ text: '编号', value: 'id',align: 'start', },
			{ text: '工人姓名', value: 'name' },
			{ text: '角色', value: 'role' },
			{ text: '出生日期', value: 'date_of_birth' },
			{ text: '开始日期', value: 'meta.start_date' },
			{ text: '结束日期', value: 'meta.end_date' },
	        { text: '功能', value: 'actions', sortable: false, width: 150 ,align: 'center'}
		],
		teamTypes: [
			{ value: 'team', text: '团队' },
			{ value: 'individual', text: '个人' }],
		itemPerPage: 999,
		activeRole: 'worker,contractor,site-manager',
		status: 0,
		loading: false,
		filter_start_date: '',
		filter_end_date: '',
		post_start_date: '',
		post_end_date: '',
		startDateMenu: false,
		endDateMenu: false,
		dlgFilterTeamWorker: false,
		dialogSearchWorker: false,
		isFiltering: false,
		assignedWorkers: [],
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		overlay: false,
		deleteWorker: null
	}),
	computed: {
		...mapState('user', ['user'],),
		teamStatus(){
			return this.teamData.archived ? '归档状态' :  '激活状态';
		}
	},
	filters: {
		dateFormat(value){
			let formatDate = parse(value, 'yyyy-MM-dd', new Date());
			return format(formatDate, 'dd/MM/yyyy');
		},
		roleType(value) {
			if (value === 'worker') {
				return 'Worker'
			} else if (value === 'team-leader') {
				return 'Team Leader'
			} else if (value === 'site-manager') {
				return 'Site Manager'
			} else if (value === 'contractor') {
				return 'Contractor'
			} else if (value === 'admin') {
				return '系统管理员'
			} else  {
				return value;
			}
		}
	},
	watch: {
		filter_start_date(val){
			this.post_start_date = this.formatDate(val);
		},
		filter_end_date(val){
			this.post_end_date = this.formatDate(val);
		},
		dlgConfirmation(){
			if (this.dlgConfirmation === false) {
				this.deleteWorker = null;
			}
		}
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		computedDateFormattedDatefns (date) {
			return date ? this.formatDate(date) : ''
		},
		formatDate (date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		async getTeamDetail(team_id){
			try {
				let response;
				if(this.isFiltering) {
					let param = {
						start_date: this.post_start_date ? this.post_start_date : '',
						end_date: this.post_end_date ? this.post_end_date : '',
					};
					response = await WorkTeamService.getWorkTeam(team_id, param);
				} else{
					response = await WorkTeamService.getWorkTeam(team_id);
				}
				const team = response.data.data;
				this.teamData.id = team.id;
				this.teamData.name = team.name;
				this.teamData.type = team.type;
				this.teamData.owner = team.owner;
				this.teamData.workers = team.workers.map(worker=> worker.id);
				this.assignedWorkers = team.workers;
	
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		filterWorker(){
			this.isFiltering = true;
			this.dlgFilterTeamWorker = false;
			this.loading = true;
			this.getTeamDetail(this.team_id)
		},
		clearFilters(){
			this.isFiltering = false;
			this.filter_start_date = null;
			this.filter_end_date = null;
			this.loading = true;
			this.getTeamDetail(this.team_id)
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here
			const payload = {
				name: this.teamData.name,
				type: this.teamData.type,
				workers: this.teamData.workers,
				archived: this.archived
			}
			this.updateWorkTeam(payload)
		},
		async updateWorkTeam(body){
			try {
				let response = await WorkTeamService.updateWorkTeam(this.team_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `团队${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
					//	this.getTeamDetail();
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.overlay = false;
					this.snackbar.model = true;
			}
		},
		assignWorkerToTeam(worker){
			const isWorkerOnTeam = this.assignedWorkers.filter((assignedWorker)=>{
				return assignedWorker.id === worker.id;
			})
			if (isWorkerOnTeam.length){
				this.snackbar.message = `工人${worker.name}已经在这个团队中了，请勿添加重复工人。`;
				this.snackbar.color = 'error';
				this.overlay = false;
				this.snackbar.model = true;
				return;
			} else {
				this.assignedWorkers.push(worker);
				this.teamData.workers.push(worker.id)
				this.onFormSubmit();
			}
			
		},
		confirmDeletion(){
			this.assignedWorkers.forEach( (worker, index)=>{
				if(worker.id === this.deleteWorker.id){
					this.assignedWorkers.splice(index, 1);
				}
			});
			this.teamData.workers.forEach((id,index)=>{
				if(id === this.deleteWorker.id){
					this.teamData.workers.splice(index,1);
				}
			})
			this.onFormSubmit();
			this.dlgConfirmation = false;
		},
		removeWorker(worker){
			this.deleteWorker = worker;
			this.dlgConfirmation = true;
		},
		onFilterRole(roleName){
			this.activeRole = roleName;
		},
		onFilterStatus(status){
			this.status = status;
		},
	} 
}
</script>
